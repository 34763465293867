<template>
  <VAutocomplete
    :label="label"
    filled
    outlined
    clearable
    :class="customClass"
    :value="value"
    item-text="nome"
    item-value="id"
    :items="pacientes"
    v-model="innerValue"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Paciente',
    },
    inputClass: {
      type: [String, Array, Object],
      default: '',
    },
  },
  data: () => ({
    innerValue: null,
  }),
  watch: {
    innerValue(value) {
      this.$emit('input', value);
    },
  },
  computed: {
    ...mapGetters('Paciente', ['pacientes']),
    customClass() {
      const defaultClass = ['pa-0 ma-0'];
      return [...defaultClass, ...this.inputClass];
    },
  },
  methods: {
    ...mapActions('Paciente', ['getPacientes']),
  },
  mounted() {
    this.getPacientes();
  },
};
</script>
