<template>
  <v-container fluid class="px-12 pt-5">
    <InputBuscaPacientes v-model="pacienteID" />

    <v-row>
      <v-col cols="12" md="7" class="px-6 pt-7">
        <v-card class="grey lighten-4">
          <v-card-text>
            <h1 class="text-center">Agenda Diária</h1>
          </v-card-text>
        </v-card>
        <v-select
        class="mt-4 mb-n8"
          :items="nomesMedicosExecutantes"
          label="Selecione um médico"
          clear-icon="$clear"
          clearable
          filled
          outlined
          @change="handleMedicoChange"
          v-model="medicoSelect"
        ></v-select>
        <v-data-table
          :headers="headers"
          :items="consultasSelecionadas"
          :loading='this.loading'
          sort-by="consulta.hora"
          loading-text="Carregando pagamentos"
          class="grey lighten-4 mt-5 px-0 clique maiuscula elevation-1"
          hide-default-footer
          no-data-text="Nenhum pagamento neste dia "
        >
        <template v-slot:item.examesList="{ item }">
          <span
              v-for="(exame, index) in item.exames"
              :title="`${exame}`"
              :key="index"
            >
              {{ exame.valorExameConvenio.exame.nome}} <br />
            </span>
            
        </template>
       <template v-slot:item.action="{ item }">
            <v-chip @click="irParaPagamento(item)" small>
              {{ item.pagamento.statusPagamento }}
              
            </v-chip>
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12" md="5">
        <Calendario class="pa-0" />

        <div class="ma-3">
          <v-row>
            <v-col cols="12" md="12">
              <div class="pa-0">
                <v-btn block class="pa-md-7 pa-2 grey darken-1">
                  <h2
                    class="
                      grey--text
                      text--lighten-4 text-center text-button text-md-h5
                      font-weight-bold
                    "
                  >
                    ENCAMINHAR FINANCEIRO
                  </h2>
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <!-- <v-row>
                <v-col cols="10" md="10">
                  <div>
                    <v-btn block class="pa-md-7 pa-2 grey darken-1">
                      <h2 class="grey--text text--lighten-4 text-center text-button text-md-h5 font-weight-bold">
                        ENCAMINHAR PARA FINANCEIRO
                      </h2>
                    </v-btn>
                  </div>
                </v-col>
              </v-row> -->
        </div>
      </v-col>
    </v-row>
    <!-- <div>
      <v-button @click="closeModal"> Close </v-button>
      <v-button @click="irParaPagamento({ statusPagamento: 'pago' })">
        Pago
      </v-button>
      <v-button @click="irParaPagamento({ statusPagamento: 'em débito' })">
        Em débito
      </v-button>
    </div> -->

    <component
      :is="modal.modalComponent"
      v-bind="{ show: modal.active }"
      @close="closeModal"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import MedicoMixin from '@mixins/MedicoMixin';
import InputBuscaPacientes from '@components/global/InputBuscaPacientes.vue';
import Calendario from '@components/Calendario.vue';
import ConsultasMixin from '@/mixins/ConsultasMixin';
import EfetuarPagamentoModal from '@components/pagamento/modals/EfetuarPagamentoModal.vue';
import VisualizarPagamentoModal from '@components/pagamento/modals/VisualizarPagamentoModal.vue';
import { EMBRION_API_URL } from '../../data/enviroment';
import moment from 'moment';


export default {
  mixins: [
    ConsultasMixin,
    MedicoMixin,
    ],
  components: {
    Calendario,
    InputBuscaPacientes,
  },
  data: () => ({
    pacienteID: null,
    urlBase: EMBRION_API_URL,
    loading: true,
    medicoSelect: '',
    
    
    modal: {
      active: false,
      modalComponent: EfetuarPagamentoModal,
    },
    exames:[],





  }),
  created(){
    //...mapGetters(['dataSelecionada','consultasSelecionadas','getConsultas']),
    
    
    



  },
  mounted(){
    this.loading = true;
    this.getNomesMedicosExecutanteSolicitante()
    
    try {
          this.getConsultaByDataMedico({
              medicoId: this.medicoSelecionado.id ? this.medicoSelecionado.id : 1,
              data: this.dataSelecionada,
            }).then(()=> { this.loading = false;});
          } catch {
          
          }

  },
  computed: {
    ...mapGetters('Auth', ['headers']),
    ...mapGetters('Pagamento', ['pagamentos']),
    ...mapGetters(['dataSelecionada','consultasSelecionadas']),


    headers() {
      return [
      { text: 'Horário', value: 'hora', width: '20%' },
        { text: 'Médico', value: 'medico.nome', align: ' d-none' },
        {
          text: 'Exame',
          value: 'examesList',
          width: '40%',
        },
        { text: 'Paciente', value: 'paciente.nome', width: '30%' },
        { text: 'Status', value: 'pagamento.status', width: '15%' },

        { text: ' ', value: 'action', sortable: false },
      ];

    },

  },

  watch: {
    //...mapActions(['Consulta', ['consultasSelecionadas', 'getConsultas']]),
    dataSelecionada: {
      async handler(value) {
        if (!value) return;
        


        this.getPagamentosPorData(value);
        try {
          this.getConsultaByDataMedico({
              medicoId: this.medicoSelecionado.id ? this.medicoSelecionado.id : 1,
              value,
            });
          } catch {
          
          }

        this.closeModal();
        








      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('Consulta',['getConsultas']),
    ...mapActions('Pagamento', ['getPagamentosPorData']),
    async irParaPagamento(consulta) {


      this.setConsultaSelecionada(consulta);
      //await this.buscaExames(consulta.consultaId,consulta.medicoExecutante.nome,consulta.convenio);
      switch (consulta.pagamento.statusPagamento.toLowerCase()) {
        case 'em débito':
          this.modal.modalComponent = EfetuarPagamentoModal;
          break;
        case 'pago':
          this.modal.modalComponent = VisualizarPagamentoModal;
          break;
        default:
          this.modal.modalComponent = null;
          break;
      }

      if (!this.modal.modalComponent) return;
      
    

      this.modal.active = true;
    },
    handleMedicoChange(nome) {
      this.loading = true;

      if (nome === null || nome === undefined) {
        this.resetaMedicoSelecionado();
        return;
      }
      let medico = this.getMedicoByNome(nome);
      this.setMedicoSelecionado(medico);
      if(medico.id){
      this.getConsultaByDataMedico({
        medicoId: medico.id,
        data: this.dataSelecionada,
      }).then(() => {
       
        this.loading = false;
        this.getdatasBloqueadas(this);
      }).catch(()=>{
       
        this.loading = false;
        this.getdatasBloqueadas(this);
      }).catch(()=>{
        
      });
    }
  },
    async closeModal() {
      try {

          await this.getConsultaByDataMedico({
              medicoId: this.medicoSelecionado.id ? this.medicoSelecionado.id : 1,
              data: this.dataSelecionada,
            });
          } catch {
          
          }finally{
            this.$forceUpdate()
          }
      this.modal.active = false;
      this.modal.modalComponent = null;
      
    },
    //pega as consultas com todos exames
    async buscaConsultas(value){
      if (!value) return;

      const resp = await axios.get(`${this.urlBase}ExamePorConsulta/ByData?data=${value}`,
      {
        headers: this.headers
      });
      const consultasFiltradas = [];
      const encontrou = [];
      for(var i = 0; i < resp.data.length; i++){


        for(var j =0; j< resp.data.length; j++){
          if( resp.data[i].consultaId == resp.data[j].consultaId && !encontrou.includes(resp.data[i].consultaId)){
          encontrou.push(resp.data[i].consultaId);
          consultasFiltradas.push(resp.data[i]);
          }
        }
      };
      for(var k = 0; k < consultasFiltradas.length ; k++){

        const resp = await axios.get(`${this.urlBase}ExamePorConsulta/?consultaId=${consultasFiltradas[k].consultaId}`,
        {
          headers: this.headers
        });
        const examesPorConsulta=[];
        let teste = resp.data;


        for (var i = 0; i < Object.keys(Object.assign({}, resp.data)).length; i++){
        let exame = {

        exame: teste[i].valorExameConvenio.exame.nome,

        };
        examesPorConsulta.push(exame);


      }
      consultasFiltradas[k].exames = examesPorConsulta;
      }








      this.exames = consultasFiltradas;



    },

    //busca exames por consulta (iddaconsulta)
    async buscaExames(value, medicoExecutante,convenio ){
      const resp = await axios.get(`${this.urlBase}ExamePorConsulta/?consultaId=${value}`,null);
      let teste = resp.data;
      const examesPorConsulta = [];

      for (var i = 0; i < Object.keys(Object.assign({}, resp.data)).length; i++){
        let exame = {
        medicoSolicitante: medicoExecutante,
        convenio: convenio,
        exame: teste[i].valorExameConvenio.exame.nome,
        valor: teste[i].valorExameConvenio.valor,
        };
        examesPorConsulta.push(exame);


      }
      this.consultaSelecionada.exames = examesPorConsulta;

      return examesPorConsulta;




    },

  },
};
</script>
