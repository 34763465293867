<template>
  <v-dialog v-model="show" transition="dialog-top-transition">

    <v-card class="px-4 py-4">
      <span class="d-flex justify-end">
                <v-icon class="pa-3" @click="close">
                  mdi-close-circle-outline
                </v-icon>
              </span>
      <h1 class="text-center">Pagamento</h1>


      <div class="d-flex flex-column">
        <h3 class="mt-4">Informações gerais</h3>
        <v-row dense class="mt-3">
          <v-col cols="12">
            <v-text-field label="NOME COMPLETO" class="maiuscula " readonly maiuscula solo dense v-model="consultaSelecionada.paciente.nome"/>
          </v-col>
          <v-col cols="4">
            <v-text-field

              label="DATA DE NASCIMENTO"
              readonly
              solo
              dense
              v-model="consultaSelecionada.paciente.dataNascimento"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field type="tel" label="CPF" readonly solo dense v-model="consultaSelecionada.paciente.cpf"/>
          </v-col>
          <v-col cols="4">
            <v-text-field label="GÊNERO" readonly solo dense v-model="consultaSelecionada.paciente.genero"/>
          </v-col>
          <v-col>
            <v-text-field type="tel" label="TELEFONE" readonly solo dense v-model="consultaSelecionada.paciente.telefonePrincipal"/>
          </v-col>
          <v-col cols="8">
            <v-text-field type="email" label="EMAIL" readonly solo dense v-model="consultaSelecionada.paciente.email"/>
          </v-col>
        </v-row>
      </div>

      <div class="d-flex flex-column">
        <h3 class="mt-4">Informações da consulta</h3>
        <v-row dense class="mt-3">
          <v-col cols="6" v-if="consultaSelecionada.medicoSolicitante!= null && consultaSelecionada.medicoSolicitante != 'null'">
            <v-text-field label="MÉDICO SOLICITANTE" class="maiuscula" readonly solo dense v-model="consultaSelecionada.medicoExecutante.nome"/>
          </v-col>
          <v-col cols="6">
            <v-text-field label="DATA" readonly solo dense v-model="consultaSelecionada.data"/>
          </v-col>
          <v-col cols="6">
            <v-text-field label="HORÁRIO" type="time" readonly solo dense v-model="consultaSelecionada.hora"/>
          </v-col>
          <v-col cols="6">
            <v-text-field label="CONVÊNIO" readonly solo dense v-model="consultaSelecionada.convenio" />
          </v-col>
        </v-row>
      </div>
      <h2 class="pb-8 pt-8">PROCEDIMENTOS</h2>
        
        <div>
        <v-row  v-for="(procedimento, indexProc) of procedimentos"
          :key="indexProc">
          <v-col cols="12">
            <h3>PROCEDIMENTO {{ indexProc + 1 }}</h3>
          </v-col>
          
       
          
          <v-col cols="12">
        
          <v-row class="mt-1 ml-2" v-for="(teste, indexExame) in procedimentos[indexProc].proc.exames"
          :key="indexExame">
          <v-col cols="10">
            
            <v-text-field
                  class="text-center"
                  
                  
                
                  readonly
                  dense
                  v-model="procedimentos[indexProc].proc.exames[indexExame].nome"
                />
            
          </v-col>
          
          
          </v-row>
        </v-col>
        
        

        </v-row>
      </div>

      <!-- <div class="d-flex justify-end">
        <v-btn @click="openAll">Abrir Todos</v-btn>
        <v-btn @click="closeAll" class="ml-4">Fechar Todos</v-btn>
      </div> -->
<!-- 
      <v-expansion-panels v-model="panelsOpen" multiple class="mt-4">
        <v-expansion-panel v-for="(exame, index) of consultaSelecionada.exames" :key="index">
          {{ exame }}
          <v-expansion-panel-header>
            EXAME {{ index + 1 }} -
            {{consultaSelecionada.exames[index].exame}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex flex-column">
              <v-row dense class="mt-3">
                <v-col cols="6">
                  <v-text-field
                    label="MEDICO EXECUTANTE"
                    class="maiuscula"
                    readonly
                    solo
                    dense
                    v-model="consultaSelecionada.medicoExecutante.nome"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="CONVÊNIO"
                    solo
                    dense
                    readonly
                    v-model="consultaSelecionada.convenio"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="EXAME"
                    solo
                    readonly
                    dense
                    v-model="exame.nome"
                  />
                </v-col>
              </v-row>

              <div class="d-flex flex-column align-center">
                <span class="text-center">VALOR</span>
                <v-text-field
                  class="text-center"
                  label="Total"
                  type="tel"
                  prefix="R$"
                  solo
                  readonly
                  dense
                  v-model="exame.valor"
                />
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> -->




      <div class="d-flex flex-column align-center mt-3">
        <br><br>
        <v-row dense class="mt-3">

          <v-col cols="12">
            <h3 class="text-center">Valor Total</h3>
            <v-text-field label="TOTAL" readonly type="tel" prefix="R$" solo dense v-model="consultaSelecionada.pagamento.valorFinal"/>
          </v-col>

        </v-row>
        <span class="text-center">Forma de Pagamento</span>
        <v-select
          
          dense
          solo
          label="Forma de pagamento"
          class="text-center"
          :items="this.formasPagamento"
          v-model="consultaSelecionada.pagamento.formaDePagamento"
        />
        {{ consultaSelecionada.pagamento.formaDePagamento }}
        <span v-if="consultaSelecionada.pagamento.formaDePagamento == 'CARTAO'" class="text-center">Número de Parcelas</span>
        <v-select
        v-if="consultaSelecionada.pagamento.formaDePagamento == 'CARTAO'"
       
             
              label="Número de Parcelas"
              class="maiuscula"
              
              dense
          solo
              
              :items="this.quantidadeParcelas"
              v-model="consultaSelecionada.quantidadeDeParcelas"
              
              
            />
        <span class="text-center">Desconto</span>
        <v-text-field
              label="DESCONTO"
              type="tel"
              prefix="R$"
              solo
              dense
              v-model="consultaSelecionada.pagamento.desconto"
            />
      </div>
      

      <div class="d-flex flex-column">
        <v-row dense class="mt-3">
          <!-- <v-col cols="6">
            <v-text-field
              label="VALOR RECEBIDO"
              type="tel"
              prefix="R$"
              solo
              dense
            />
          </v-col> -->
          <!-- <v-col cols="6">
            <v-select
              label="FORMA DE DESCONTO"
              type="tel"
              :items="['PORCENTAGEM %', 'VALOR R$']"
              solo
              dense
            />
          </v-col> -->
          <!-- <v-col cols="6">
            <v-text-field label="TROCO" type="tel" prefix="R$" solo dense />
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="DESCONTO"
              type="tel"
              prefix="R$"
              solo
              dense
              v-model="consultaSelecionada.pagamento.desconto"
            />
          </v-col> -->
          <v-col cols="6">
            <v-btn block @click="abreModal" >ENCAMINHAR PARA O FINANCEIRO</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block @click="efetuaPagamento" >REALIZAR PAGAMENTO</v-btn>
          </v-col>
        </v-row>
      </div>

        <component
        :is="modal.modalComponent"
        v-bind="{ show: modal.active }"
        @close="closeModal"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import ConsultasMixin from '@/mixins/ConsultasMixin';
import SubModalOcorrenciaVue from '@/components/financeiro/SubModalOcorrencia.vue';
import moment from 'moment';
export default {
  mixins: [
    ConsultasMixin,
    ],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    exames: [
      {
        exame: '',
        valor: '',
      },
    ],
    procedimentos: [],
    panelsOpen: [],
    examesPorConsulta: [],
    modal: {
      active: false,
      modalComponent: SubModalOcorrenciaVue,
    },
  }),
  created() {
    for (let exame of this.consultaSelecionada.exames){
        if(this.procedimentos[exame.procedimento] == undefined){
          this.procedimentos[exame.procedimento] = {
            proc: {
              exames: [exame]
            },
            valor: "",
            procedimento: exame.procedimento 
          }

        }else{
          this.procedimentos[exame.procedimento].proc.exames.push(exame)
        }
       
      }
     
     

  },

  methods: {
    close() {
      this.$emit('close');
      try {
          this.getConsultaByDataMedico({
              medicoId: this.medicoSelecionado.id ? this.medicoSelecionado.id : 1,
              data: this.dataSelecionada,
            });
          } catch {
          
          }

    },
    openAll() {
      this.panelsOpen = [...Array(this.consultaSelecionada.exames).keys()].map((_, index) => index);
    },
    closeAll() {
      this.panelsOpen = [];
    },
    async efetuaPagamento(){
      await this.efetuarPagamentoModal(this.consultaSelecionada.consultaId);
      try {
          this.getConsultaByDataMedico({
              medicoId: this.medicoSelecionado.id ? this.medicoSelecionado.id : 1,
              value,
            });
          } catch {
          
          }

      alert("Pagamento Efetuado");
     

      this.close();
    },
    abreModal(){
      //this.mostraOcorrencia = true;
      this.modal.active = true;
    },
    async closeModal() {
      this.modal.active = false;
    },
    submitOcorrencia(){
    },
  },
};
</script>
